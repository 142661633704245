const __request = require(`./__request/__request_contentType_json`);

// 国际机票订单信息
const recordCount = (data) => {
let pParameter = {
    method: 'post',
    urlSuffix:'/flight/international/buyer/white/order/reserve/params',
    data:data
  }

  return __request(pParameter)
}
export default recordCount
