//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import FareBtn from "./components/fareType";
import { ProcurementRuleText } from "../ProcurementRule.js";
import airParams from "@/lib/data-service/flight/flight_international_buyer_white_order_reserve_params";
import reserve from "@/lib/data-service/flight/flight_international_buyer_white_order_reserve";
import countryList from "@/lib/data-service/flight/flight_buyer_country_list";
import checkPrice from "@/lib/data-service/flight/flight_international_buyer_white_order_checkPrice";
import { resolve } from "q";
import { getSession } from "@/lib/deprecated/utils/caching";

export default {
  data() {
    var cardNo = (rule, value, callback) => {
      let reg = /(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
      let re = new RegExp("(^([PSE]{1}\\d{7}|[GS]{1}\\d{8})$)");
      if (reg.test(value) === false) {
        callback(new Error("请输入正确身份证号码"));
      }
    };
    var mobile = (rule, value, callback) => {
      let reg = /^((\+?86)|(\(\+86\)))?(13[012356789][0-9]{8}|15[012356789][0-9]{8}|18[023456789][0-9]{8}|147[0-9]{8}|1349[0-9]{7})$/;
      if (reg.test(value) === false) {
        callback(new Error("请输入正确手机号码"));
      }
    };
    var psgNameCn = (rule, value, callback) => {
      let reg = /^[0-9]+.?[0-9]*$/;
      if (!value) {
        callback(new Error("请输入乘机人姓名"));
      } else if (reg.test(value)) {
        callback(new Error("输入姓名有误！"));
      } else if (value.length < 2) {
        callback(new Error("请输入正确姓名"));
      }
    };
    var detectionEmail = (rule, value, callback) => {
      let reg = /^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/;
      if (reg.test(value) === false) {
        callback(new Error("请输入正确邮箱号码"));
      }
    };
    var detectionQQ = (rule, value, callback) => {
      let reg = /^[1-9][0-9]{4,10}$/gim;
      if (reg.test(value) === false) {
        callback(new Error("请输入正确QQ号码"));
      }
    };
    var inspectName = (rule, value, callback) => {
      let reg = /[A-Za-z]+/;
      if (!value) {
        return callback("请输入姓名");
      }
      if (reg.test(value) === false) {
        callback(new Error("请输入拼音简写"));
      }
    };
    return {
      // 航班信息
      ordersData: null,
      arrowsInfoShow: [],
      peopleInfo: {
        show: false
      },
      clauseShow: null,
      trunkShow: null,
      // 乘客数量
      passengerVal: 1,
      loading: false,

      // 乘客类型
      psgTypeOption: [
        {
          value: 1,
          label: "成人",
          disabled: false
        },
        {
          value: 2,
          label: "儿童",
          disabled: false
        },
        {
          value: 3,
          label: "婴儿",
          disabled: false
        }
      ],
      sexList: [
        {
          value: "MALE",
          label: "男"
        },
        {
          value: "FEMALE",
          label: "女"
        }
      ],
      fareList: [],
      // 联系人信息
      contact: {
        contactEmail: "",
        contactName: "",
        remark: "",
        contactPhone: "",
        contactQQ: ""
      },
      ProcurementRuleText: ProcurementRuleText,
      // 旅客证件
      cardType: [
        { value: 1, name: "护照" },
        { value: 2, name: "身份证" },
        { value: 3, name: "其他" }
      ],
      // 是否同意胤之旅规则
      checked: false,
      warningShow: false,
      // 乘客信息
      passenger: [
        {
          birthday: "",
          firstName: "",
          gender: "MALE",
          idExpiration: "",
          idNumber: "",
          idType: 1,
          lastName: "",
          nationality: "",
          phoneNumber: "",
          pnr: "",
          type: 1
        }
      ],
      ProcurementRuleShow: false,
      // 乘客类型
      psgType: 3,
      // 输入框验证规则
      rules: {
        cardNo: [{ validator: cardNo, required: true, trigger: "blur" }],
        idNumber: [
          { required: true, message: "请填写证件号码", trigger: "blur" }
        ],
        birthday: [
          { required: true, message: "请选择出生日期", trigger: "change" }
        ],
        phoneNumber: [{ validator: mobile, required: true, trigger: "blur" }],
        psgNameCn: [{ validator: psgNameCn, required: true, trigger: "blur" }],
        contactName: [
          { validator: psgNameCn, required: true, trigger: "blur" }
        ],
        idExpiration: [
          { required: true, message: "请选择证件有效期", trigger: "change" }
        ],
        firstName: [
          { validator: inspectName, required: true, trigger: "blur" }
        ],
        lastName: [{ validator: inspectName, required: true, trigger: "blur" }],
        contactPhone: [{ validator: mobile, required: true, trigger: "blur" }],
        contactEmail: [
          { validator: detectionEmail, required: true, trigger: "blur" }
        ],
        contactQQ: [
          { validator: detectionQQ, required: true, trigger: "blur" }
        ],
        nationality: {
          required: true,
          message: "搜索后选择国籍",
          trigger: "change"
        }
      },
      // 成人数量
      adultNub: 1,
      // 儿童数量
      childNub: 0,
      babyNub: 0,
      dialogVisible: false,
      invoicePrice: 0,
      moneyList: {},
      taxes: 0,
      AllPrice: 0,
      PriceText: "",
      rate: 1,
      picList: [],
      image_url_airline_blank_map: `https://lyn.oss-cn-shenzhen.aliyuncs.com/temp/airline-blank-map.jpg`,
      formData: {},
      nationalityList: []
    };
  },
  components: { FareBtn },

  methods: {
    // 计算周几
    Getweek(time) {
      var str = "";
      var week = new Date(time).getDay();
      if (week == 0) {
        str = "星期日";
      } else if (week == 1) {
        str = "星期一";
      } else if (week == 2) {
        str = "星期二";
      } else if (week == 3) {
        str = "星期三";
      } else if (week == 4) {
        str = "星期四";
      } else if (week == 5) {
        str = "星期五";
      } else if (week == 6) {
        str = "星期六";
      }
      return str;
    },
    goRule() {
      this.ProcurementRuleShow = true;
    },
    // 通过身份证获取生日
    fromIdCard(val, list) {
      let birthday = "";
      if (val != null && val != "") {
        if (val.length == 15) {
          birthday = "19" + val.substr(6, 6);
        } else if (val.length == 18) {
          birthday = val.substr(6, 8);
        }
        birthday = birthday.replace(/(.{4})(.{2})/, "$1-$2-");
      }
      list.birthday = birthday;
    },
    getCountryList() {
      countryList().then(res => {
        if (res.code == this.SUCCESS_CODE) {
          this.nationalityList = res.countrys;
        }
      });
    },
    // 获取信息并计算价格
    getAirParams() {
      let _s = sessionStorage.getItem("userInfo");
      let _this = this;
      let orderInfo, airInfo, airSegmentInfo;
      this.airInfo = airInfo = getSession("AIRINFO");
      this.airSegmentInfo = airSegmentInfo = 
        getSession("AIRSEGMENTINFO")
      
      this.orderInfo = orderInfo = getSession("ORDERINFO")
      let passengerNum =
        orderInfo.adultCount + orderInfo.babyCount + orderInfo.childCount;
      this.passengerVal = Number(passengerNum);
      delete orderInfo.pageSize;
      delete orderInfo.currentPage;
      delete orderInfo.orderField;
      delete orderInfo.orderType;
      delete orderInfo.takeOffInterval;

      this.airInfo.priceData.map(item => {
        item.passengerType === 1
          ? (this.moneyList.adult = item)
          : item.passengerType === 2
            ? (this.moneyList.child = item)
            : (this.moneyList.baby = item);
      });
      let _obj = {
        beginTime: airInfo.beginTime,
        cabinCodes: airInfo.cabinCodes,
        flightNo: airInfo.flightNo,
        planeTypes: airInfo.planeTypes,
        ticketAirLineCode: airInfo.ticketAirline,
        supplierId: airInfo.supplierId
      };
      let data = Object.assign(orderInfo, _obj);
      this.formData = data;
      this.ordersData = airInfo;
      this.gitPrice();
    },
    setPeopleInfo() {
      this.peopleInfo.show = true;
      let data = {
        childCount: this.orderInfo.childCount,
        adultCount: this.orderInfo.adultCount,
        babyCount: this.orderInfo.babyCount
      };
      this.peopleInfo = Object.assign(this.peopleInfo, data);
    },
    // 深克隆
    deepClone(obj) {
      let _obj = JSON.stringify(obj);
      let objClone = JSON.parse(_obj);
      return objClone;
    },
    // 计算成人数量
    compAdultNub(data) {
      return data.psgType === 1;
    },
    // 计算儿童数量
    compChildNub(data) {
      return data.psgType === 2;
    },
    // 底部文字部分
    gitPrice() {
      let list = this.moneyList;
      // console.log(this.airInfo)
      let people = this.orderInfo;
      for (let i in list) {
        if (list[i].passengerType === 1) {
          var _a = list[i].saleTotal * people.adultCount;
        } else if (list[i].passengerType === 2) {
          var _b = list[i].saleTotal * people.childCount;
        } else {
          var _c = list[i].saleTotal * people.babyCount;
        }
      }
      this.AllPrice = (
        _a +
        (_b ? _b : 0) +
        (_c ? _c : 0) +
        this.airInfo.yzlBillingFee
      ).toFixed(2);
      // if (this.orderInfo.flightType == 2) {
      //   this.AllPrice = this.AllPrice * 2;
      // }
    },
    querySearch(queryString, cb, items) {
      let restaurants = this.nationalityList;
      let results;
      results = queryString
        ? restaurants.filter(this.createStateFilterAirLines(queryString))
        : [];

      cb(results);
      // results.length === 0 ? (items.nationalityText = "") : "";
    },
    handleSelect(val, item) {
      item.nationality = val.code;
    },
    createStateFilterAirLines(queryString) {
      return state => {
        if (state.cnName.indexOf(queryString) != -1) {
          state.value = state.cnName;
          return state;
        } else if (state.enName.indexOf(queryString.toUpperCase()) != -1) {
          state.value = state.cnName;
          return state;
        }
      };
    },
    gainPrice() {
      let data = Object.assign(this.formData, this.peopleInfo);
      this.loading = true;
      airParams(data).then(
        res => {
          this.ordersData = res.flights[0];
          this.loading = false;
          this.getFare(this.peopleInfo);
          res.flights[0].priceData.map(item => {
            item.passengerType === 1
              ? (this.moneyList.adult = item)
              : item.passengerType === 2
                ? (this.moneyList.child = item)
                : (this.moneyList.baby = item);
          });
          this.gitPrice();
        },
        () => {
          this.loading = false;
        }
      );
    },
    // 提交表单
    submit() {
      let segmentListArr = [];
      let formArr = ["touchFrom"];
      let _this = this;
      segmentListArr = this.ordersData.segmentList.map(item => {
        let arr = [];
        arr = item.routeList.map(sitem => {
          let _a = {
            sTerminal: sitem.sTerminal,
            eCityCode: sitem.eCityCode,
            arriveDate: sitem.arriveDate,
            planeType: sitem.planeType,
            arriveTime: sitem.arriveTime,
            cabinCode: sitem.cabinCode,
            duration: sitem.duration,
            eAirportCode: sitem.eAirportCode,
            eTerminal: sitem.eTerminal,
            beginDate: sitem.beginDate,
            flightNo: sitem.flightNo,
            codeShare: sitem.codeShare,
            airCompanyCode: sitem.airCompanyCode,
            beginTime: sitem.beginTime,
            sCityCode: sitem.sCityCode,
            segmentId: item.segmentId,
            cabinRank: this.orderInfo.cabinRank,
            sAirportCode: sitem.sAirportCode
          };
          return _a;
        });
        let segment = {
          segmentType: item.segmentType,
          routeList: arr
        };
        return segment;
      });

      function verify(arr) {
        let statu = [];
        arr.map((formName, index) => {
          let _a = _this.$refs[formName][0] || _this.$refs[formName];
          _a.validate(valid => {
            if (valid) {
              statu.push(true);
            } else {
              statu.push(false);
            }
          });
        });
        return statu;
      }

      function verifyArr() {
        let arr = [];
        _this.passenger.map((item, index) => {
          return arr.push("userForm" + index);
        });
        return new Promise(resolve => resolve(arr));
      }
      let priceData = {
        adultCount: this.orderInfo.adultCount,
        airline: this.ordersData.ticketAirline,
        babyCount: this.orderInfo.babyCount,
        childCount: this.orderInfo.childCount,
        segmentList: this.ordersData.segmentList,
        supplierId: this.ordersData.supplierId
      };
      if (!this.checked) {
        return this.$message({
          message: "请同意《胤之旅国内旅游机票采购规则》",
          type: "error"
        });
      }
      function submitOrder() {
        return new Promise(resolve => {
          checkPrice(priceData).then(res => {
            if (!res.status) {
              return _this.$message({
                type: "warning",
                message: "价格有差异,该订单无法支付!"
              });
            }
            resolve(res);
          });
        });
      }

      verifyArr().then(array => {
        let statu = verify(formArr.concat(array));
        console.log(statu);
        if (statu.indexOf(false) == -1) {
          _this.loading = true;
          submitOrder().then(val => {
            let data = {
              flightId: this.ordersData.flightId,
              inquiryNumber: val.serialNumber,
              isConvert: val.isConvert,
              noticeMobileNumber: this.contact.contactPhone,
              officeNumber: this.ordersData.officeNumber,
              serialNumber: this.ordersData.serialNumber,
              supplierId: this.ordersData.supplierId,
              ticketAirlineCode: this.ordersData.ticketAirline,
              passengerList: this.passenger,
              segmentList: segmentListArr
            };
            data = Object.assign(data, this.contact);
            console.log(data);
            reserve(data).then(
              res => {
                _this.loading = false;
                this.$router.push({
                  name: "international-pay",
                  query: {
                    ordersNo: res.orderNo,
                    type: "international"
                  }
                });
              },
              () => {
                _this.loading = false;
              }
            );
          });
        }
      });
    },
    getFare(val) {
      let orderInfo;
      val ? (orderInfo = val) : (orderInfo = this.orderInfo);
      let adultCount = [],
        babyCount = [],
        childCount = [];
      function getNewArr(arr, length, type) {
        let passenger = {
          birthday: "",
          firstName: "",
          gender: "MALE",
          idExpiration: "",
          idNumber: "",
          idType: 1,
          lastName: "",
          nationality: "",
          phoneNumber: "",
          pnr: "",
          type: 1
        };
        for (let i = 0; i < length; i++) {
          passenger.type = type;
          arr.push(passenger);
        }
      }
      orderInfo.adultCount > 0
        ? getNewArr(adultCount, orderInfo.adultCount, 1)
        : "";
      orderInfo.babyCount > 0
        ? getNewArr(babyCount, orderInfo.babyCount, 3)
        : "";
      orderInfo.childCount > 0
        ? getNewArr(childCount, orderInfo.childCount, 2)
        : "";
      let arr = [...adultCount, ...babyCount, ...childCount];
      console.log(arr);
      this.passenger = JSON.parse(JSON.stringify(arr));
    },
    getNumber(val) {
      //  let adult = this.orderInfo.adultCount
      //  let child = this.orderInfo.childCount
      //  let baby = this.orderInfo.babyCount
      //  if(val.type==='adult'){
      //   if(adult>=val.num){
      //       val.minus = adult - val.num
      //   }else{
      //       val.add = val.num - adult
      //   }
      //   this.orderInfo.adultCount = val.num
      // }else if(val.type==='child'){
      //   if(child>=val.num){
      //       val.minus = child - val.num
      //   }else{
      //       val.add = val.num - child
      //   }
      //   this.orderInfo.childCount = val.num
      // }else{
      //   if(baby>=val.num){
      //       val.minus = baby - val.num
      //   }else{
      //       val.add = val.num - baby
      //   }
      //   this.orderInfo.babyCount = val.num
      // }
      // this.setPassengerVal(val)
      let people = this.peopleInfo;
      if (val.type === "adult") {
        people.adultCount = val.num;
      } else if (val.type === "child") {
        people.childCount = val.num;
      } else {
        people.babyCount = val.num;
      }
    },
    setPassengerVal(val) {
      let list = this.passenger;
      let _this = this;
      let passenger = {
        birthday: "",
        firstName: "",
        gender: "MALE",
        idExpiration: "",
        idNumber: "",
        idType: 1,
        lastName: "",
        nationality: "",
        phoneNumber: "",
        pnr: "",
        type: 1
      };
      if (val.add >= 0) {
        if (val.type == "adult") {
          passenger.type = 1;
        } else if (val.type == "child") {
          passenger.type = 2;
        } else {
          passenger.type = 3;
        }
        list.push(passenger);
      } else {
        if (val.type == "adult") {
          setArr(list, 1);
        } else if (val.type == "child") {
          setArr(list, 2);
        } else {
          setArr(list, 3);
        }
      }
      function setArr(list, type) {
        for (let i = 0; i < list.length; i++) {
          if (list[i].type === type) {
            _this.passenger.splice(i, 1);
            return;
          }
        }
      }
    }
  },
  watch: {},
  created() {
    this.getAirParams();
    this.getFare();
    this.getCountryList();
  }
};
