
export const ProcurementRuleText =
   `
    <p>1. 订单生成：您下单生成订单后，不得随意变更订座记录信息，若有变更必须重新下订单；</p>
    <p>2. PNR重利用说明：对于曾出过票的记录，在平台采购下订单前，需删除PNR内的运价项（FC/FN/FP）、EI备注项、TN票号项及关联项SSR TKNE；</p><p>3. 订单支付：由于航空公司会根据航班售益状况随时清舱，您下订单后必须及时进行支付，若因没有及时支付被NO位的，由您负责；距航班起飞时间≤2小时的订单，请慎重下单；</p>
    <p>4. NO位处理：在出票过程中出现NO位的，您应配合平台及时补座位及客人证件信息。如无法补到同舱位的航段，出票过程中≤10分钟被航空公司NO位由您方负责，如因您PNR中存在问题（如航段不连续、PNR无法入库、证件信息不准确等）无法出票，沟通过程中（不限≤10分钟的情况）出现NO位的，由您负责；</p>
    <p>5. 订单出票结果检查：订单处理完成后，您需自行检查订单处理是否正确（PNR票号有效性、航段状态、价格项和身份证件号码等相关信息），如有问题须及时跟平台客服联系。您检查订单处理无误后须在平台上进行确认操作；</p>
    <p>6. 客票退改签处理：退票、机票改期、签转将根据不同航空公司、不同舱位的相应规定进行操作，出票后如修改、签转、退票所产生费用由您全额承担；</p>
    <p>7. 客票变更及处理检查：客票如需变更时，可以根据航空公司客规自行进行变更；如无法自行变更要平台提交变更申请的，由平台负责变更。在平台上提交客票变更申请的，处理完成后，您须自行核对变更信息（PNR中行程、承运人、舱位、改签时间、身份证号码、关联项、用DETR：TN/票号指令检查航段日期时间是否已由OPEN状态改为客户指定的旅行日期时间），检查无误后确认结束订单；</p>
    <p>8. 客票作废规定：当日客票如需作废，您须按各航空公司规定在航班起飞前提交客票作废申请（B2B客票必须在航班起飞2小时前提交），若因没有及时提交导致无法作废的，由您自行承担。提交客票作废申请前，须取消PNR并作废行程单，当日客票作废按10元/张收费，申请须在当日22：00前提交，若因没有及时取消PNR、作废行程单，或没有在当日22：00前提交，造成的客票作废失败，由您自行承担。客票作废完成后，您必须确认PNR中票号为VOID或REFUNDED状态；若您因实际需求要保留PNR（例：同一名客人在此PNR中存在两个有效票号，其中一个保留），须在退废票申请中明确说明，退款将最终在取消PNR记录或重出票后退回，如因PNR未取消，虚占航空公司机位，出现ADM罚单，由您完全承担；</p>
    <p>9. 客票退票：在客人确认需退票时，您须第一时间取消PNR并在平台提交退票申请，提交退票申请前须作废行程单，提交退票申请时须注明退票类型，如自愿退票的，须注明按客规退票的费率和手续费金额；如需全退的，须注明原因并提供相关证明，若无相关证明或不能及时提供，导致无法全退或延迟退款的，您自行承担；公司收到航司退款后退给您，以航司实际退款审核金额为准。</p>
    <p>10. 重出全退规定：如需重出全退的，因各出票地的航空公司有不同规定，重出票或提交全退申请前须与平台客服人员联系确认；</p>
    <p>11. 勿重复下单、重复支付：您不能提交支付重复的PNR（同一位乘机人相同时间段两张以上的机票订单），如提交重复订单导致重复出票，所产生的退废票损失由您承担；</p>
    <p>12. 特殊旅客说明：目前平台只能提供普通旅客服务，对于婴儿及其他有特殊要求的旅客，如病残旅客、孕妇、轮椅旅客，无成人陪伴儿童、VIP要客、国家重要特殊军官军人等，如您在平台采购出票VIP票、儿童票、无成人陪伴儿童票、特殊要客票等，需由您负责向航空公司申报特殊服务要求；</p>
    <p>13. 失信通知：失信人不得乘飞机。如乘机人属失信人，如未提前说明，机票一旦付全款之后，失信人的机票全损，只能退税，所有损失我司不承担责任！！国家法院失信人验证网站为：http://shixin.court.gov.cn/</p>
    <p>14. 航班延误通知：平台仅提供各地机票销售政策信息，任何临时发生的航班取消或变动，您自行负责清Q 并及时通知自己的客人，平台不承担任何责任，若出票时平台更换过记录编玛，由平台负责通知您；</p>
    <p>15. 平台提示信息：交易过程中，您须查看平台上的所有提示信息，这些信息均为采购规则的一部分，须严格遵守；</p>
    <p>16. 更换编码订单：需采购自行检查原编码是否需要取消;</p>
    <p>17. 其他事宜：本采购须知中未涉及的交易规则等未尽事宜按国内运输客规规定执行。</p>
    <p>18. 请确保PNR内容真实准确，虚假PNR出票方有权拒绝出票。</p>
    <p>19. 请确认PNR的姓名、舱位、航段组正确，请确认舱位状况。</p>
    <p>20. 请确认每个旅客均已输入SSR FOID项。</p>
    <p>21. 如有恶意占座、恶意修改PNR内容、恶意签转、联程客票弃程等违反航空公司规定的采购行为，采购方需承担相应责任，平台保留向采购方追偿的权力。</p>
    <p>22.甲方在承诺期限内完成退款的订单，若与航司审核结果不相符，则以航司审核为准，由此产生的差价则应由乙方负责承担。乙方拒绝承担的，以及违反平台服务规范的，甲方有权限从乙方其他退票单里扣除相应的金额。</p>
    <p>23.外国人旅藏的有关规定Notes for foreigners to Tibet  </p>
     <p>1）根据国家旅游局规定，外国旅游者、台湾游客和海外华人（香港、澳门居民持中国特区护照或回乡证者除外）在藏期间不允许自行旅游，不论客人人数多少，必须有组织、有计划地组团旅游。According to the provision from the National Tourism Bureau, foreign visitors, Taiwan visitors and overseas Chinese (except the Hong Kong, Macao residents who hold the passport of Special Administrative Region or the ratification of returning homeland) in Tibet are not allowed to travel by themselves. They must travel in group whatever there are how many visitors. </p>  
    <p>2）鉴于西藏特殊的民族传统、文化古迹和生态环境保护的需要，同时也因西藏的交通状况和旅游服务设施及接待能力，政府规定非中国公民身份持有者，即外国旅游客人，台湾游客及海外华人（香港、澳门居民持中国特区护照或回乡证者除外），在进入西藏旅游前，必须办理由西藏自治区旅游局[TTB]签发的“外国人入藏旅游批准函”，同时，也规定外国记者和外交官不能以旅游者身份进藏。</p>
    <p>Considering the need of Tibet’s special ethnic customs, historical sites and ecological environment, as well as traffic conditions, service facilities and reception places, the government enforces a provision that before travel in Tibet, non-Chinese citizens must handle “the ratification of foreigners’ travel to Tibet”, which need possess a permit by TTB(Tibet Travel Bureau). And these non-Chinese citizens include foreign country visitors, visitors from Taiwan and overseas Chinese (excluding the people in Hong Kong, Macao who hold the passport of special administrative region (SAR) or the ratification of returning homeland), in addition, the government also has another provision that foreign journalists or diplomats can’t enter into Tibet as the visitors.平台服务承诺</p>
    <p>1. 平台客户服务时间8：00-24：00，出票时间以各政策所发布的信息为准；</p>
    <p>2. 平台承诺自支付成功起30分钟内完成出票，如超过30分钟未出票导致航空公司NO位的（采购须知中第3、4条情况除外），出票方承担赔偿责任，赔偿限额最高不超所预定舱位票面差价；</p>
    <p>3. 平台保证正常退/废票及取消交易，在5个工作日（周末和节假日顺延）内完成订单的退/废票或取消交易处理，收到航空公司退票款后并退（除退票费或废票费等航空公司收取的费用外）至采购方支付宝账户；</p>
    <p>4. 平台只接收在线支付方式，银行卡信息和支付密码在交易中不经过平台系统，直接以安全通道方式递交支付网关，支付网关是受法律保护的支付平台</p>`
