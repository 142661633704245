const __request = require(`./__request/__request_contentType_form`);

// 国籍列表
const recordCount = (data) => {
let pParameter = {
    method: 'post',
    // urlSuffix: '/flight/buyer/country/list',
    urlSuffix:'/base/dom/countrys',
    data:data
  }

  return __request(pParameter)
}
export default recordCount
